<template>
  <div class="wrpp1">
    <div class="top2">
      <div class="topPT">
        <button class="btn" @click="goToJoin">立即申请入驻</button>
        <div class="toppic">
          <img  class="toBe" src="https://xcx.alicdn2.hexiaoxiang.com/web_static/homeTop.png"/>
          <img class="tojoin" src="https://xcx.alicdn2.hexiaoxiang.com/web_static/JoinTop.png"/>
        </div>
      </div>
    </div>
    <div class="join">
      <div class="joincontainer">
        <p>为什么选择在河小象工作</p>
        <div class="work">
          <div class="workdiv" v-for="(item,index) in workList" :key="index">
            <img class="workimg" :src="item.url"/>
            <p class="worktext">{{item.text}}</p>
          </div>
        </div>
        <p>入驻资格</p>
        <div class="require">
          <div class="leftimg">
            <p>什么资格可以成为老师?</p>
          </div>
          <div class="rightimg">
            <p>本科书法专业毕业</p>
            <p>5年以上书法教学经验</p>
            <p>普通话标准流利</p>
          </div>
        </div>

        <p>入驻流程</p>
        <div class="process">
          <div class="processdiv" v-for="(item,index) in processList" :key="index">
            <img class="processimg" :src="item.url"/>
            <p class="processtext">{{item.text}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import foot from "./foot";
export default {
  data() {
    return {
      processList: [
        {
          url: "https://xcx.alicdn2.hexiaoxiang.com/web_static/test.png",
          text: "资质审核"
        },
        {
          url: "https://xcx.alicdn2.hexiaoxiang.com/web_static/train.png",
          text: "总部培训"
        },
        {
          url: "https://xcx.alicdn2.hexiaoxiang.com/web_static/exam.png",
          text: "上岗考试"
        }
      ],

      workList: [
        {
          url: "https://xcx.alicdn2.hexiaoxiang.com/web_static/rich.png",
          text: "竞争力的薪酬"
        },
        {
          url: "https://xcx.alicdn2.hexiaoxiang.com/web_static/free.png",
          text: "稳步的发展空间"
        },
        {
          url: "https://xcx.alicdn2.hexiaoxiang.com/web_static/nice.png",
          text: "专业的培训"
        }
      ]
    };
  },
  components: {
    foot
  },
  mounted() {
    document.addEventListener("click", this.hidelist);
    var that = this;
    that.passNo();
    window.onscroll = function() {
      var t = document.documentElement.scrollTop || document.body.scrollTop;
      if (t >= 648) {
        that.passYes();
      } else {
        that.passNo();
      }
    };
  },
  methods: {
    passYes() {
      this.$emit("changeHeader");
    },
    passNo() {
      this.$emit("notChangeHeader");
    },
    goToJoin() {
      window.open("https://www.wjx.top/vm/tU5X29y.aspx#");
    }
  }
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
p {
  color: #515558;
  font-size: 56px;
  line-height: 60px;
  font-weight: 450;
}
.btn {
  width: 400px;
  height: 69px;
  background: rgba(255, 154, 35, 1);
  border-radius: 30px;
  color: white;
  font-size: 26px;
  line-height: 40px;
  cursor: pointer;
  outline: none;
  border: none;
  position: absolute;
  bottom: 108px;
  left: 0;
}
.btn:hover {
  background: rgba(255, 154, 35, 0.8);
}
.wrpp1 {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 1200px;
}
.foot1 {
  display: flex;
  display: -webkit-flex;
  width: 100%;
  background-color: #333333;
  justify-content: center;
}
.top2 {
  width: 100%;
  height: 636px;
  background-color: #ffda2c;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-width: 1200px;
}
.joincontainer {
  height: 1563px;
  width: 1180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.join {
  height: 1706px;
  width: 1180px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.work {
  width: 1180px;
  height: 367px;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-between;
}
.workdiv {
  width: 370px;
  height: 367px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
  overflow: hidden;
}
.workimg {
  width: 370px;
  height: 253px;
  background-color: grey;
}
.worktext {
  font-size: 20px;
  margin-top: 27px;
  font-weight: 550;
  color: #515558;
}
.require {
  width: 915px;
  height: 340px;
  display: flex;
  display: -webkit-flex;
  align-items: space-between;
}
.leftimg {
  width: 561px;
  height: 340px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.26);
  position: relative;
  z-index: 100;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.leftimg p {
  font-size: 30px;
  font-weight: 600;
}
.rightimg {
  background-color: #eef6fd;
  width: 419px;
  height: 340px;
  z-index: 99;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
  border-right: 0px white;
}
.rightimg p {
  font-size: 20px;
  font-weight: 400;
}
.show {
  width: 1180px;
  height: 330px;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
}
.showdiv {
  position: relative;
  width: 370px;
  height: 330px;
  border-radius: 8px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.26);
  background-color: #434343;
  overflow: hidden;
}
.showimg {
  height: 330px;
  width: 370px;
  background-color: #e5e5e5;
}
.showtext {
  position: absolute;
  bottom: 0;
  width: 370px;
  height: 70px;
  font-size: 16px;
  color: white;
  font-weight: 400;
  background-color: rgba(0, 0, 0, 0.26);
}
.showtext span {
  font-size: 30px;
  font-weight: 500;
}
.process {
  width: 1138px;
  height: 238px;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
}
.processdiv {
  width: 276px;
  height: 242px;
}
.processimg {
  width: 176px;
  height: 176px;
  border-radius: 50%;
  background-color: #e3ebf6;
  overflow: hidden;
}
.processtext {
  font-size: 26px;
}
.toppic {
  width: 1200px;
  height: 585px;
}
.topPT {
  width: 1200px;
  height: 585px;
  position: relative;
}
.toBe {
  position: absolute;
  top: 150px;
  left: 30px;
}
.tojoin {
  position: absolute;
  top: 0px;
  right: 0px;
}
</style>
